import { BuyNow, Policy, Checkout } from '@/core/types/BuyNow';


export type State = {
  buyNow: BuyNow | null;
  checkout: Checkout;
  policy: Policy;
}

export const state: State = {
  buyNow: null,
  checkout: {
    amount: 0,
    bai_car_insurance_policy_id: 0,
    bai_quote_id:0,
    created_at:'',
    created_by:0,
    customer_id:0,
    email:'',
    expiry_date_time:'',
    id:0,
    intransact_policy_id:0,
    invoice_number:'',
    is_agency_repair:0,
    is_amend:0,
    language:'',
    lead_id:0,
    manufacturer:'',
    merchant_reference:'',
    model:'',
    name:'',
    order_description:'',
    original_price:0,
    payment_option:'',
    payment_type:0,
    payment_type_text:'',
    phone_number:'',
    policy_sales_agent_id:0,
    status:0,
    status_text:'',
    trim_level:'',
    updated_at:'',
    vat:0
  },
  policy: {
    add_per_car_price: '',
    add_percentage_req: 0,
    addons:[],
    age_group_validation:0,
    agency_percentage:0,
    agency_repair:0,
    agency_repair_id:0,
    ar_id:'',
    car_policy_highlight_title:[],
    car_price_check:'',
    countries:[],
    discard:'',
    dl_exp_cond_percentage:false,
    excess_amount:'',
    flat_percentage_rate:'',
    flat_rate:'',
    flat_rate_rule:0,
    gcc_cover:0,
    hdlex:'',
    home_dl_exp:0,
    home_driving_rta_validation:0,
    id:0,
    image: '',
    is_agency:'',
    min_price_per:'',
    min_rate:0,
    min_rate_applied:'',
    nar_id:0,
    new_policy_rate:0,
    new_rate:'',
    personal_accident_driver:0,
    personal_accident_passenger:0,
    personal_accident_passengers_amt:0,
    policy_fee:'',
    policy_name: '',
    policy_price: '',
    policy_type:0,
    provider_name: '',
    rental_cash_benefit:0,
    roadside_assist_amount:0,
    roadside_assist_flag:0,
    roadside_assistance:[],
    sale_price: '',
    sort_order:0,
    special_features:'',
    terms_url:'',
    third_party_damage_limit:'',
    uae_dl_exp:0,
    udlex:'',
    value_add_to_compare:false,
    value_personal_driver:false,
    value_personal_passenger:false,
    value_read_more:false,
    vat: '',
    windscreen_cover:0
}
};
import { MutationTree } from 'vuex';

import { Comprehensive, ThirdParty, TotalLoss } from '@/core/types/Qoutes';

import { State } from './state';
import { QoutesMutationTypes } from '@/core/enums/Qoutes/Mutations';

export type Mutations<S = State> = {
  [QoutesMutationTypes.SET_COMPREHENSIVES](state: S, payload: Array<Comprehensive>): void;
  [QoutesMutationTypes.SET_COMPREHENSIVES_FIFTYFIFTY](state: S, payload: Array<Comprehensive>): void;
  [QoutesMutationTypes.SET_THIRD_PARTIES](state: S, payload: Array<ThirdParty>): void;
  [QoutesMutationTypes.SET_TOTAL_LOSS](state: S, payload: Array<TotalLoss>): void;
  [QoutesMutationTypes.SET_COMPARE_DATA](state: S, payload: Array<Object>): void;
  [QoutesMutationTypes.SET_COMPARED_DATA](state: S, payload: Array<Object>): void;
  [QoutesMutationTypes.SET_IS_LOADING](state: S, payload: Boolean): void;
  [QoutesMutationTypes.SET_NO_RECORD](state: S, payload: Boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [QoutesMutationTypes.SET_COMPREHENSIVES](state: State, comprehensive: Array<Comprehensive>) {
    state.comprehensive = comprehensive;
  },
  [QoutesMutationTypes.SET_COMPREHENSIVES_FIFTYFIFTY](state: State, comprehensive: Array<Comprehensive>) {
    state.comprehensivefiftyfifty = comprehensive;
  },
  [QoutesMutationTypes.SET_THIRD_PARTIES](state: State, thirdParty: Array<ThirdParty>) {
    state.thirdParty = thirdParty;
  },

  [QoutesMutationTypes.SET_TOTAL_LOSS](state: State, totalLoss: Array<TotalLoss>) {
    state.totalLoss = totalLoss;
  },
  [QoutesMutationTypes.SET_COMPARED_DATA](state: State, merge: Array<Comprehensive>) {
    state.comparedData = merge;
  },
  [QoutesMutationTypes.SET_IS_LOADING](state: State, payload: Boolean) {
    state.isLoading = payload;
  },
  [QoutesMutationTypes.SET_NO_RECORD](state: State, payload: Boolean) {
    state.hasData = payload;
  },
  [QoutesMutationTypes.SET_COMPARE_DATA](state: State, payload: Array<Object>) {
    // const indexOfObject = state.compareData?.findIndex(object => {
    //   return object === payload;
    // });
    // indexOfObject !== -1 ? state.compareData?.splice(<number>indexOfObject, 1) : state.compareData?.push(payload);
    // state.isCompareOpen = <number>state.compareData?.length > 1 ? true : false;
    // state.isDisable = <number>state.compareData?.length > 3 ? true : false;
    window.localStorage.setItem('compare_data', JSON.stringify(payload));
    state.compareData?.push(payload);
  },
};

<template>
    <section class="vechile-detail-section">
    <div class="container">

        <div class="vechile-detail-container">
            <div class="vechile-detail_row">
                <div class="row gx-5 justify-content-center">
                    <div class="col-lg-9">
                        <div class="vechile-detail__block">
                            <div class="row justify-content-center">


                                <div class="col-lg-11 col-md-7 form-left-column">
                                    <div
                                        class="page-title-block d-flex align-items-center flex-wrap justify-content-between page-title-container">
                                        <div class="page-title mb-0 ">
                                            <!-- <h2 class="mb-0">Vehicle Details</h2> -->
                                        </div>
                                        <div class="page-title-desc">
                                            <h5 class="mb-0">This will only take 1 minute!</h5>
                                        </div>
                                    </div>

                                    <div class="vechile-details-form">
                                        <form>
                                            <div class="vechile-details-list">
                                                <div class="form-block">
                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Car Year </label>
                                                        </div>
                                                    </div>

                                                    <div class="input-block">
                                                        <div class="row">
                                                            <div class="col-lg-10">


                                                                <select class="select-input-skeleton" disabled>
                                                                </select>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <!-- v-if="isShowBrandNew" -->
                                                <div class="form-block" style="display: none">
                                                    <div class="label-block">
                                                        <div
                                                            class="d-flex justify-content-between w-100 switch-container">
                                                            <div class="label-block_label">
                                                                <label>Is your car brand new?</label>
                                                            </div>
                                                            <div class="switch-toggle">
                                                                <input id="a" type="checkbox" disabled v-model="defaultValue">
                                                                <label for="a">
                                                                    <div class="switch-toggle__switch"
                                                                        data-checked="No" data-unchecked="Yes">
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="label-block_question position-relative">
                                                            <a class="popper" data-bs-trigger="hover"
                                                                placement="right" data-bs-toggle="popover">
                                                                <div class="icon">
                                                                    <span class="icon-question-icon"></span>
                                                                </div>
                                                            </a>

                                                            <div class="popper-content-block d-none">
                                                                <div class="popper-content">
                                                                    <p>Lorem Ipsum is simply dummy text of the
                                                                        printing and typesetting industry. Lorem
                                                                        Ipsum has been the industry's standard
                                                                        dummy
                                                                        text ever since the 1500s, when an
                                                                        unknown
                                                                        printer took a galley of type and
                                                                        scrambled
                                                                        it to m
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-block">
                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Car Brand </label>
                                                        </div>
                                                        <div class="label-block_question position-relative">
                                                            <!-- <div class="icon">
                                                                <span class="icon-question-icon"></span>
                                                            </div>
                                                            <div class="title position-absolute">
                                                                Lorem, ipsum dolor sit amet consectetur
                                                                adipisicing
                                                            </div> -->
                                                            <a class="popper" data-bs-trigger="hover"
                                                                placement="right" data-bs-toggle="popover">
                                                                <div class="icon">
                                                                    <span class="icon-question-icon"></span>
                                                                </div>
                                                            </a>

                                                            <div class="popper-content-block d-none">
                                                                <div class="popper-content">
                                                                    <p>Lorem Ipsum is simply dummy text of the
                                                                        printing and typesetting industry. Lorem
                                                                        Ipsum has been the industry's standard
                                                                        dummy
                                                                        text ever since the 1500s, when an
                                                                        unknown
                                                                        printer took a galley of type and
                                                                        scrambled
                                                                        it to m
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="input-block">
                                                        <div class="row">
                                                            <div class="col-lg-10">


                                                                <select class="select-input-skeleton" disabled>
                                                                </select>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="form-block">
                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Car Model </label>
                                                        </div>

                                                    </div>

                                                    <div class="input-block">
                                                        <div class="row">
                                                            <div class="col-lg-10">


                                                                <select class="select-input-skeleton" disabled>
                                                                </select>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="form-block">
                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Model details</label>
                                                        </div>

                                                    </div>

                                                    <div class="input-block">
                                                        <div class="row">
                                                            <div class="col-lg-10">


                                                                <select class="select-input-skeleton" disabled>
                                                                </select>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="form-block estimated-block">
                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Your estimated car value</label>
                                                        </div>
                                                        <div class="label-block_question position-relative">
                                                            <!-- <div class="icon">
                                                                <span class="icon-question-icon"></span>
                                                            </div>
                                                            <div class="title position-absolute">
                                                                Lorem, ipsum dolor sit amet consectetur
                                                                adipisicing
                                                            </div> -->
                                                            <a class="popper" data-bs-trigger="hover"
                                                                placement="right" data-bs-toggle="popover">
                                                                <div class="icon">
                                                                    <span class="icon-question-icon"></span>
                                                                </div>
                                                            </a>

                                                            <div class="popper-content-block d-none">
                                                                <div class="popper-content">
                                                                    <p>Lorem Ipsum is simply dummy text of the
                                                                        printing and typesetting industry. Lorem
                                                                        Ipsum has been the industry's standard
                                                                        dummy
                                                                        text ever since the 1500s, when an
                                                                        unknown
                                                                        printer took a galley of type and
                                                                        scrambled
                                                                        it to m
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="input-block">
                                                        <div class="row">
                                                            <div
                                                                class="col-lg-10 position-relative currency-input-block">


                                                                <!-- <select class="select-input-skeleton">
                                                                    <option>$ </option>
                                                                    <option>$ </option>
                                                                    <option>$ </option>
                                                                </select> -->
                                                                <input type="text" class="custom-input-skeleton" disabled>
                                                                <span class="currency-input">$</span>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="form-block estimated-block">
                                                    <div class="estimated-value">
                                                        <div class="title">
                                                            <h6 class="mb-0">Your car value is estimated <span>
                                                                    from $ 812,175 to $ 999,999</span>
                                                            </h6>
                                                        </div>
                                                        <div class="desc">
                                                            <p>Estimate price is subject to insurance company
                                                                approval</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-block">
                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Which emirate will you register your
                                                                car?</label>
                                                        </div>
                                                        <div class="label-block_question position-relative">
                                                            <!-- <div class="icon">
                                                                <span class="icon-question-icon"></span>
                                                            </div>
                                                            <div class="title position-absolute">
                                                                Lorem, ipsum dolor sit amet consectetur
                                                                adipisicing
                                                            </div> -->
                                                            <a class="popper" data-bs-trigger="hover"
                                                                placement="right" data-bs-toggle="popover">
                                                                <div class="icon">
                                                                    <span class="icon-question-icon"></span>
                                                                </div>
                                                            </a>

                                                            <div class="popper-content-block d-none">
                                                                <div class="popper-content">
                                                                    <p>Lorem Ipsum is simply dummy text of the
                                                                        printing and typesetting industry. Lorem
                                                                        Ipsum has been the industry's standard
                                                                        dummy
                                                                        text ever since the 1500s, when an
                                                                        unknown
                                                                        printer took a galley of type and
                                                                        scrambled
                                                                        it to m
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="input-block">
                                                        <div class="row">
                                                            <div class="col-lg-10">


                                                                <select class="select-input-skeleton" disabled>
                                                                </select>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="form-block">


                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Full Name </label>
                                                        </div>
                                                    </div>

                                                    <div class="input-block">
                                                        <div class="row">
                                                            <div class="col-lg-10">
                                                                <input type="text"
                                                                    class="custom-input-skeleton" disabled>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="form-block">
                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Phone Number </label>
                                                        </div>
                                                    </div>

                                                    <div class="input-block-phone">
                                                        <div class="row">
                                                            <div class="col-lg-10">
                                                                <div
                                                                    class="phone-num position-relative d-flex align-items-center bg-white">
                                                                    <div class="dial-code position-relative">
                                                                        <select class="phone-num-select"
                                                                            id="callback-country" name="country"
                                                                            title="Country Code" tabindex="-1"
                                                                            aria-hidden="true" disabled></select>
                                                                    </div>
                                                                    <div class="phone-num-input">
                                                                        <input type="text" id="callback-phone"
                                                                            name="phone" disabled>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>

                                                <div class="form-block">
                                                    <div class="label-block">
                                                        <div class="label-block_label">
                                                            <label>Which emirate will you register your
                                                                car?</label>
                                                        </div>

                                                    </div>

                                                    <div class="input-block">
                                                        <div class="row">
                                                            <div class="col-lg-10">
                                                                <input type="email" placeholder="Email address"
                                                                    class="custom-input-skeleton" disabled>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <!-- end -->
                                            </div>

                                            <div class="submit-btn text-md-end text-center">
                                                <div class="row">
                                                    <div class="col-lg-10">
                                                        <button type="submit" class="btn">Proceed</button>
                                                    </div>
                                                </div>
                                            </div>


                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>

    </div>
</section>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useStore } from '@/store';

export default defineComponent({
    setup() {
        const store = useStore();
        const isShowBrandNew = ref(true)
        const vehicle = computed(() => {
            return store.state.vehicles.vehicle;
        })

        onMounted(() => {
            const yearToday = new Date().getFullYear();
            const yearLastyear = yearToday - 1;
            if([yearToday, yearLastyear].includes(vehicle.value.car_year)) {
                isShowBrandNew.value = true
            } else {
                isShowBrandNew.value= false
            }
        })

        const defaultValue = ref(true);
        

        return {
            vehicle,
            defaultValue,
            isShowBrandNew
        }
    },
})
</script>

<template>
    <el-dialog
    class="box-card"
    v-model="isOpen"
    center
    width="100%"
  >
    <template #header>
      <div class="card-header">
        <h2 class="text-center" style="overflow-wrap: break-word">{{ popupHeading }}</h2>
      </div>
    </template>
    <p class="system_err_msg" v-html="popupBody">        
    </p>
  </el-dialog>
</template>

<script setup>
import { store } from "@/store";
import { defineExpose, ref } from "vue";

const isOpen = ref(false);
const popupHeading = ref('');
const popupBody = ref('');

function open(data) {
    isOpen.value = true;
    popupHeading.value = data.bodily_injury_hdi_data.heading;
    popupBody.value = data.bodily_injury_hdi_data.body;
}

function refresh() {
    window.location.reload()
}

defineExpose({ open })

</script>

<style>
.system_err_msg{
display: block;
word-break: break-word;
}
</style>

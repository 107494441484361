import { createI18n } from 'vue-i18n'

const messages = {
    en: {
        test: "English",
        side_title: "Please take care to answer all questions honestly and to the best of your knowledge.",
        side_sub_title: "We've provided help text and guidance to help you enter the right information. We recommend you use it when getting a quote.",
        side_footer: "Join over 1 million customers with free £250 excess",
        vehicle: "Vehicle Details",
        driver: "Driver Details",
        compare: "Compare Qoutes",
        secure_checkout: "Secure Checkout",
        cover: "Your Cover",
        login: "Log in",
        title: "This will only take",
        minute: "1 minute!",
        car_registration_year: "Car Year",
        is_car_brand_new: "Is your car brand new?",
        is_car_brand_new_tool_tip: "Is your car brand new?",
        car_brand: "Car Brand",
        car_brand_tool_tip: "Input the manufacturer's name of your vehicle. For example, Toyota, Nissan, Honda",
        car_model: "Car Model", 
        model_details: "Model Details", 
        estimated_car_value: "Your estimated car value",
        estimated_car_value_tool_tip: "Enter the current market value of your car in USD. If unsure, check online car valuation tools or consult a car dealer. Please note that the value provided by you and the insurance company may differ based on various factors.", 
        estimated_car_value_notif: "Enter what you believe is the current resale value.Remember that a higher valuation means a higher insurance price", 
        estimated_car_value_from: "Your car value is estimated", 
        AED: "$", 
        froms: "from", 
        to: "to", 
        insurance_company_approval: "Estimate price is subject to insurance company approval", 
        emirates: "Which emirate will you register your car?", 
        emirates_tool_tip: "Which emirate will you register your car?", 
        emirates_placeholder: "Select Emirate Registration", 
        full_name: "Full Name", 
        full_name_paceholder: "Full Name", 
        phone_number: "Phone Number", 
        phone_number_placeholder: "Phone Number", 
        email: "Email Address", 
        email_placeholder: "Email Address", 
        proceed: "Proceed",
        wait: "Please wait...",
        dob: "Date of Birth",
        nationality: "Nationality",
        nationality_tool_tip: "Nationality",
        first_driving_license: "Which country issued your first driving license?",
        first_driving_license_tool_tip: "Which country issued your first driving license",
        first_registration_date: "Car first registration date",
        first_registration_date_tool_tip: "You can find this date on your registration card",
        home_country_driving_experience: "How many years of Home country driving experience do you have?",
        home_country_driving_experience_tool_tip: "How many years of Home country driving experience do you have?",
        uae_driving_experience: "How many years of UAE driving experience do you have?",
        uae_driving_experience_tool_tip: "How many years of UAE driving experience do you have",
        policy_start_date: "When do you want your policy to start?",
        policy_start_date_tool_tip: "Policy start date should be the same date as when the car registration needs to be done. Once the policy has been started please register within 10 days in Dubai and 2 days in other Emirates",
        claims_with_your_insurance: "Have you made any claims with your insurance provider in the  last 12 months?",
        claims_with_your_insurance_tool_tip: "Have you made any claims with your insurance provider in the  last 12 months?",
        no_claims: "How many years with no claims?",
        no_claims_save: "(Save up to 20%)",
        no_claims_tool_tip: "How many years with no claims",
        confirm_details: "Confirm Details",
        gcc_specification: "My car is GCC specification & has not been modified.",
        car_personal_use: "My car is for personal use only.",
        is_current_policy_active: "Is your current insurance policy active?",
        is_current_policy_comprehensive: "Is the current policy fully comprehensive?",
        bottom_float: "Your insurer may require extra information to issue your policy. This may include photographs of your car, your RTA passing certificate and, in some cases they may need to inspect your car",
        comprehensice_plans: "Comprehensive Plans",
        third_party_plans: "Third Party Only Plans",
        no_record: "No record found!",
        lowest_price: "Lowest Price",
        own_damage: "Own Damage Cover",
        lebanon: "Lebanon",
        bodily_injury_limit: "Bodily Injury Limit",
        material_damage_limit: "Material Damage Limit",
        third_party_limit: "Third Party Limit Up to",
        third_party_limits: "Third Party Limit",
        excess_amount: "Excess Amount",
        car_coverage:"Car Coverage",
        extra_coverage:"Extra Coverage",
        additional_deductible:"Additional Deductible",
        personal_accident_driver: "Personal Accident Driver",
        personal_accident_driver_passenger: "Personal Accident (Driver & Passengers)",
        medical_expenses_driver_passenger: "Medical Expenses (Driver & Passengers)",
        personal_accident_passenger: "Personal Accident Passenger",
        personal_accident_family: "Personal Accident Family",
        roadside_assistance: "Roadside Assistance",
        agency_repair: "Agency Repair",
        limit_up_to: "Limit Upto $",
        up_to: "Up to",
        total_premium: "Total Premium",
        buy_now: "Buy now",
        plan_details: "Plan Details",
        add_to_compare: "Add to compare",
        highlights: "Highlights",
        extra_addon: "Extra Addons",
        special_feature: "Special Features",
        tc: "Terms & Conditions of Insurance Agent",
        compare_offer: "Compare Offers",
        compare_title: "Compare",
        option: "Options",
        company: "Company",
        price: "Price",
        qoute_reference: "Quote reference",
        from: "from",
        unbeatable_price: "Unbeatable price",
        included: "Included",
        addons: "Addons",
        coupon: "Do you have a coupon",
        apply: "Apply",
        remove: "Remove",
        checkout: "Check Out",
        summary: "Summary",
        premium: "Premium",
        vat: "VAT",
        vat_t: "VAT value %",
        discount: "Discount",
        total_amount: "Total Amount",
        payment: "Payment",
        found: 'We have found',
        car_insurance_plan: 'Car Insurance plans',
        for: 'for',
        declared_value: 'Insured Declared Value is ',
        result_all: 'All',
        result_comprehensive: 'Comprehensive',
        result_allrisk: 'All Risk',
        result_allrisk_fiftyfifty: 'All Risk (50/50)',
        result_totalloss: 'Total Loss',
        result_tp: 'Third Party',
        price_vat: 'The prices you see here are exclusive of 5% VAT',
        more_details: 'More Details',
        less_details: 'Less Details',
        sub_title: 'This will only take 1 minute!',
        read_terms: 'Read T&C',
        coupons: 'Do you have a coupon?',
        coupon_applied: 'Coupon Applied',
        disclaimer: 'Disclaimer',
        // disclaimer_details: 'To issue your policy, your insurer may require extra information or documentation including but not limited to - Photographs of your vehicle, vehicle registration documents, vehicle inspection report etc.',
        // disclaimer_details: 'To issue your policy, your insurer may require extra information or documentation including but not limited to - Photographs of your vehicle, vehicle documents, License etc. Coverage will begin once confirmed by the insurer. ',
        disclaimer_details: 'Your policy will be issued and effective only when your insurer requirements are accomplished and approved : information - documentation including car survey - License etc. Coverage will be granted once your policy is issued by the insurer after completing all needed requests.',
        agree: 'Agree to the',
        tac: 'Terms & Conditions',
        compare_plan: 'Compare Plans',
        back: 'Back',
        details: 'Details',
        vehi: 'Vehicle',
        drive: 'Driver',
        drive_header: 'Driver',
        comp: 'Compare',
        comp_header: 'Compare',
        quotes: 'Quotes',
        quotes_header: 'Quotes',
        your: 'Your',
        cov: 'Cover',
        your_cover: 'Your Cover',
        need_assistance: 'NEED ASSISTANCE',
        call_us: 'or Call us on',
        support_center: 'Visit Support Center',
        mail: 'Email',
        head_office: 'Head Office',
        info_email: 'info@buyanyinsurance.com',
        address: 'Al Bacha Gardens Center, Block B, 1st floor, Jisr el Bacha, Lebanon',
        corporate_solution: 'Corporate Solutions',
        corporate: 'Corporate',
        solution: 'Solutions',
        get_insured: 'Get Insured',
        car_insurance: 'Car Insurance',
        health_insurance: 'Health Insurance',
        home_insurance: 'Home Insurance',
        expat_insurance: 'Expat Insurance',
        expat_health_insurance: 'Expat Health Insurance',
        bike_insurance: 'Bike Insurance',
        pet_insurance: 'Pet Insurance',
        travel_insurance: 'Travel Insurance',
        other_insurance: 'Other Insurance',
        renew_a_policy: 'Renew a Policy',
        support: 'Support',
        help_center: 'Help Center',
        contact: 'Contact',
        cancellation: 'Cancellation',
        about: 'About',
        insurance_partners: 'Insurance Partners',
        hr_solutions: 'HR Solutions',
        blog: 'Blog',
        calculator: 'Calculators',
        car_loan: 'Car Loan',
        mortgage: 'Mortgage',
        insurance_calc: 'Insurance Calc',
        quick_links: 'Quick links',
        privacy_page: 'Privacy Page',
        ami: 'North Star insurance',
        edarat: 'E-DARAT',
        disclaimers: 'DISCLAIMERS',
        disclaimer_text: 'Premium Choice Marketing LLC office 1406, Damac Smart Heights, Barsha Heights, Dubai, UAE is the owner of the brand name “ BuyAnyInsurance.com". Insurance is provided by Chedid Insurance Brokers SAL.',
        company_of: 'A company of',
        copyrights: 'Copyrights',
        coupon_ph: 'Coupon',
        car_value: 'Car Value',
        comprehensive: 'Comprehensive',
        third_party: 'Third Party',
        no_claim_certificate: 'How many times did you claim in the last 12 months?',
        show_full_summary: 'Show Full Summary',
        hide_full_summary: 'Hide Full Summary',
        no_data_found: 'Sorry, There is no record found',
        no_quotes_we_process: 'Oops, We cannot process your application online.',
        no_quotes_following: 'This could be due to either one of the following:',
        // no_quotes_point_one: 'Your vehicle has a very high value or falls under a specific vehicle model that insurance companies would like to know more about',
        // no_quotes_point_two: 'Your vehicle has been modified or has non-GCC specs',
        no_quotes_point_one: 'Your vehicle has a very high value',
        no_quotes_point_two: 'Your vehicle falls under a specific vehicle model that insurance companies would like to know more about',
        no_quotes_manual_quote_title: 'For a manual quotation, please contact us on 01 481 565 or ',
        no_quotes_another_car: 'Go back and insure another vehicle',
        no_plans_available: 'No Plans Available',
        we_cannot_process: 'We cannot process your application online. Our team will contact you shortly. This could be due to either one of the following:',
        searching_for_car_insurance: 'Searching for car insurance plan',
        contact_for_pricing: 'Call <a href="tel:01481565">01 481 565</a> for pricing',
        bike_details: 'Bike Details',
        bike: 'Bike',
        model_year:'Model Year',
        engine_capacity:'Engine Capacity',
        bike_uae_driving_license:'UAE Driving Experience',
        bike_emirates:'Which emirate will you register your bike?',
        searching_for_bike_insurance: 'Searching for bike insurance plan',
        bike_insurance_plan: 'Bike Insurance plans',
        third_party_damage_limit: 'Third Party Damage Limit',
        third_party_liability: 'Third Party Liability',
        damage_to_your_vehicle: 'Damage To Your Vehicle',
        bike_year:'Bike Year',
        date_of_birth: "Date of Birth",
        date_of_birth_tooltip: "Enter your birth date in the format DD/MM/YYYY. This helps assess your eligibility and in calculating insurance premiums.",
        admin_fee: 'Admin Fees',
        essentials: 'Essentials',
        google_map: 'Google Map',
        copyrights_footer: 'Insurance is the subject matter of solicitation. © Copyright-DATE_FOOTER BuyAnyInsurance.com.',
        one_year_cover:'1 Year Cover',
        looking_for_allrisk_plan:'Looking for All Risk insurance plans. Our team will contact you shortly.',
        click_here_to_whatsapp_us:'click here to whatsapp us',
        payable_total: "Payable Total",
        faqs: 'FAQs',
    },
    ar: {
        test: "الإنجليزية",
        side_title: "يرجى الإجابة عن جميع الأسئلة بصدق ووفقًا لمعلوماتك.",
        side_sub_title: "نقدم لك نصًا ودليلًا لمساعدتك في إدخال المعلومات الصحيحة، نوصي باستخدامها في أثناء الحصول على عرض سعر.",
        side_footer: "AR - Join over 1 million customers with free £250 excess",
        vehicle: "تفاصيل المركبة",
        driver: "تفاصيل السائق",
        compare: "قارن عروض الأسعار",
        secure_checkout: "تسجيل خروج آمن",
        cover: "تغطيتك",
        login: "تسجيل الدخول",
        title: "تستغرق العملية فقط",
        minute: "1 دقيقة",
        car_registration_year: "سنة الصنع",
        is_car_brand_new: "هل سيارتك جديدة؟",
        is_car_brand_new_tool_tip: "هل سيارتك جديدة؟",
        car_brand: "نوع السيارة",
        car_brand_tool_tip: "أدخل اسم الشركة المصنعة لسيارتك. على سبيل المثال: تويوتا أو نيسان أو هوندا.",
        car_model: "طراز السيارة", 
        model_details: "تفاصيل الطراز", 
        estimated_car_value: "القيمة التقديرية لسيارتك", 
        estimated_car_value_tool_tip: "أدخل القيمة السوقية الحالية لسيارتك بالدولار الأمريكي. إذا لم تكن متأكدًا، فتحقق من أدوات تقييم سعر السيارة عبر الإنترنت أو استشر تاجر سيارات. يرجى ملاحظة أن القيمة التي تقدمها أنت وشركة التأمين قد تختلف بناءً على عوامل مختلفة.", 
        estimated_car_value_notif: "أدخل ما هو برأيك القيمة الحالية لإعادة بيع السيارة. وتذكر بأن ارتفاع القيمة يعني ارتفاع سعر التأمين.", 
        estimated_car_value_from: "تُقدّر قيمة سيارتك بـ", 
        AED: "$", 
        froms: "من", 
        to: "إلى", 
        insurance_company_approval: "يخضع السعر التقديري لموافقة شركة التأمين.", 
        emirates: "في أي إمارة ستقوم بتسجيل سيارتك؟", 
        emirates_tool_tip: "في أي إمارة ستقوم بتسجيل سيارتك؟", 
        emirates_placeholder: "اختر تسجيل الإمارة", 
        full_name: "الاسم الكامل", 
        full_name_paceholder: "الاسم الكامل", 
        phone_number: "رقم الهاتف", 
        phone_number_placeholder: "رقم الهاتف", 
        email: "عنوان البريد الإلكتروني", 
        email_placeholder: "عنوان البريد الإلكتروني", 
        proceed: "أكمل",
        wait: "الرجاء الانتظار",
        dob: "تاريخ الولادة",
        nationality: "الجنسية",
        nationality_tool_tip: "الجنسية",
        first_driving_license: "أي دولة أصدرت أول رخصة قيادة لك؟",
        first_driving_license_tool_tip: "أي دولة أصدرت أول رخصة قيادة لك؟",
        first_registration_date: "تاريخ أول تسجيل للسيارة",
        first_registration_date_tool_tip: "يمكنك العثور على هذا التاريخ في بطاقة التسجيل الخاصة بك.",
        home_country_driving_experience: "كم عدد سنوات خبرة القيادة لديك في بلدك الأصلي؟",
        home_country_driving_experience_tool_tip: "كم عدد سنوات خبرة القيادة لديك في بلدك الأصلي؟",
        uae_driving_experience: "كم عدد سنوات خبرة القيادة لديك في الإمارات؟",
        uae_driving_experience_tool_tip: "كم عدد سنوات خبرة القيادة لديك في الإمارات؟",
        policy_start_date: "متى تريد أن تبدأ وثيقة التأمين؟",
        policy_start_date_tool_tip: "يجب أن يبدأ تاريخ الوثيقة في التاريخ ذاته لتسجيل السيارة. بمجرد بدء سريان وثيقة التأمين ينبغي لك تسجيل السيارة في غضون 10 أيام في دبي ويومين في الإمارات الأخرى.",
        claims_with_your_insurance: "هل قدمت أي مطالبة إلى شركة التأمين في الأشهر الـ 12 الماضية؟",
        claims_with_your_insurance_tool_tip: "هل قدمت أي مطالبة إلى شركة التأمين في الأشهر الـ 12 الماضية؟",
        no_claims: "كم عامًا لديك من دون وجود مطالبات؟",
        no_claims_save: "وفّر حتى 20%",
        no_claims_tool_tip: "كم عامًا لديك من دون وجود مطالبات؟",
        confirm_details: "تأكيد التفاصيل",
        gcc_specification: "سيارتي بمواصفات خليجية ولم يتم تعديلها.",
        car_personal_use: "سيارتي للاستخدام الشخصي فقط.",
        is_current_policy_active: "هل وثيقة تأمينك الحالية فعّالة؟",
        is_current_policy_comprehensive: "هل وثيقة تأمينك الحالية شاملة؟",
        bottom_float: "قد تطلب شركة التأمين معلومات إضافية لإصدار وثيقتك، يشمل ذلك صورًا لسيارتك وصلاحية شهادة المركبة من هيئة الطرق والمواصلات، وفي بعض الحالات قد يحتاجون إلى فحص سيارتك. ",
        comprehensice_plans: "خطط التأمين الشامل",
        third_party_plans: "خطط التأمين ضد الغير فقط",
        no_record: "لا يوجد سجلات",
        lowest_price: "أقل سعر",
        own_damage: "تغطية الأضرار الخاصة بك",
        third_party_limit: "حد التأمين ضد الغير حتى",
        third_party_limits: "حد التأمين ضد الغير",
        excess_amount: "نسبة التحمل",
        personal_accident_driver: "الحوادث الشخصية للسائق",
        personal_accident_passenger: "الحوادث الشخصية للراكب",
        personal_accident_family: "الحوادث الشخصية للعائلة",
        roadside_assistance: "المساعدة على الطريق",
        agency_repair: "إصلاح الوكالة",
        limit_up_to: "الحد حتى",
        up_to: "حتى",
        total_premium: "إجمالي الأقساط",
        buy_now: "اشترِ الآن",
        plan_details: "تفاصيل الخطة",
        add_to_compare: "أضف للمقارنة",
        highlights: "أبرز النقاط",
        extra_addon: "ملحقات إضافية",
        special_feature: "مميزات خاصة",
        tc: "شروط وأحكام وكيل التأمين ",
        compare_offer: "قارن العروض",
        compare_title: "قارن",
        option: "الخيارات",
        company: "الشركة",
        price: "السعر",
        qoute_reference: "مرجع عرض السعر",
        from: "AR - from",
        unbeatable_price: "سعر لا يقاوم",
        included: "متضمن",
        addons: "الملحقات",
        coupon: "هل لديك قسيمة خصم؟",
        apply: "استخدم",
        remove: "احذف",
        checkout: "الخروج",
        summary: "الملخص",
        premium: "سعر الوثيقة",
        vat: "ضريبة القيمة المضافة",
        vat_t: "ضريبة القيمة المضافة value%",
        discount: "الخصم",
        total_amount: "المبلغ الإجمالي",
        payment: "الدفع",
        found: 'لقد وجدنا',
        car_insurance_plan: 'خطط تأمين السيارة',
        for: 'لأجل',
        declared_value: 'القيمة المصرح عنها',
        result_all: 'الكل',
        result_comprehensive: 'الشامل',
        result_tp: 'ضد الغير',
        price_vat: 'الأسعار الموجودة هنا لا تشمل ضريبة القيمة المضافة بنسبة 5٪',
        more_details: 'المزيد من التفاصيل',
        less_details: 'تفاصيل أقل',
        sub_title: 'تستغرق العملية أقل من 1 دقيقة',
        read_terms: 'اقرأ شروط وأحكام وكيل التأمين ',
        coupons: 'هل لديك قسيمة خصم؟',
        coupon_applied: 'تم استخدام قسيمة الخصم',
        disclaimer: 'إخلاء مسؤولية',
        // disclaimer_details: 'لإصدار وثيقتك، قد تطلب شركة التأمين معلومات أو وثائق إضافية تتضمن على سبيل المثال لا الحصر: صور لسيارتك، وثيقة تسجيل السيارة، تقرير فحص السيارة .. إلخ.',
        // disclaimer_details:'لإصدار وثيقتك التأمينية، قد تطلب شركة التأمين معلومات أو وثائق إضافية بما في ذلك على سبيل المثال لا الحصر - صور مركبتك ووثائق المركبة والترخيص .. إلخ. ستبدأ التغطية بمجرد تأكيدها من قبل شركة التأمين.',
        disclaimer_details:'سيتم إصدار وثيقتك سارية المفعول بعد تنفيذ متطلبات شركة التأمين والموافقة عليها: المعلومات والوثائق بما في ذلك فحص السيارة والترخيص إلخ. وسيتم تقديم التغطية بمجرد إصدار وثيقة التأمين الخاصة بك من قبل شركة التأمين، وذلك بعد استكمال جميع الإجراءات المطلوبة.',
        agree: 'أوافق على',
        tac: 'الأحكام والشروط',
        compare_plan: 'قارن الخطط',
        back: 'عودة',
        details: 'تفاصيل',
        vehi: 'المركبة',
        drive: 'سائق',
        drive_header: 'السائق',
        comp: 'قارن',
        comp_header: 'العروض',
        quotes: 'يقتبس',
        quotes_header: 'قارن',
        your: 'لك',
        cov: 'التغطية',
        your_cover: 'التغطية',
        need_assistance: 'طلب المساعدة',
        call_us: 'أو اتصل بنا على',
        support_center: 'طلب المساعدة',
        mail: 'البريد الإلكتروني',
        head_office: 'المكتب الرئيسي',
        info_email: 'info@buyanyinsurance.com',
        address: 'سنتر حدائق الباشا، بلوك ب، الطابق الأول، جسر الباشا. لبنان',
        corporate_solution: 'حلول الشركات',
        corporate: 'الشركات',
        solution: 'حلول',
        get_insured: 'احصل على تأمين',
        car_insurance: 'تأمين السيارة',
        health_insurance: 'التأمين الصحي',
        expat_insurance: 'تأمين المغتربين',
        expat_health_insurance: 'التأمين الصحي للمغتربين ',
        home_insurance: 'تأمين المنزل',
        bike_insurance: 'تأمين الدراجة النارية',
        pet_insurance: 'تأمين الحيوانات الأليفة',
        travel_insurance: 'تأمين السفر',
        other_insurance: 'تأمينات أخرى',
        renew_a_policy: 'تجديد التأمين',
        support: 'الدعم',
        help_center: 'مركز المساعدة',
        contact: 'اتصل بنا',
        cancellation: 'إلغاء التأمين',
        about: 'نبذة عنّا',
        insurance_partners: 'شركاء التأمين',
        hr_solutions: 'حلول الموارد البشرية',
        blog: 'المدونة',
        calculator: 'الحاسبات',
        car_loan: 'قرض السيارة',
        mortgage: 'القرض العقاري',
        insurance_calc: 'حاسبة التأمين',
        quick_links: 'روابط سريعة',
        privacy_page: 'صفحة الخصوصية',
        ami: 'نورث ستار',
        edarat: 'E-DARAT',
        disclaimers: 'إخلاء المسؤولية',
        disclaimer_text: 'Premium Choice Marketing LLC office 1406, Damac Smart Heights, Barsha Heights, Dubai, UAE is the owner of the brand name “ BuyAnyInsurance.com". Insurance is provided by Chedid Insurance Brokers SAL.',
        company_of: 'إحدى شركات ',
        copyrights: 'الحقوق محفوظة',
        coupon_ph: 'كوبون',
        car_value: 'قيمة السيارة',
        comprehensive: 'الشامل',
        third_party: 'ضد الغير',
        // no_claim_certificate: 'هل يمكنك تقديم شهادة عدم مطالبة من مزود التأمين الحالي؟',
        no_claim_certificate: 'كم مرة قدمت مطالبة في الأشهر 12 الماضية؟',
        show_full_summary: 'عرض الملخص الكامل',
        hide_full_summary: 'إخفاء الملخص الكامل',
        no_data_found: 'مع الأسف، لم يتم العثور على أي سجل',
        no_quotes_we_process: '.عذرًا، لم نتمكن من معالجة طلبك أون لاين',
        no_quotes_following: ':قد يكون هذا لأحد الأسباب التالية',
        // no_quotes_point_one: 'لسيارتك قيمة سوقية عالية جدًا أو لها طراز محدد تود شركة التأمين معرفة المزيد عنه ',
        // no_quotes_point_two: 'تم تعديل سيارتك أو ليس لها مواصفات خليجية',
        no_quotes_point_one:'مركبتك لها قيمة عالية جدًا.',
        no_quotes_point_two:' تندرج مركبتك ضمن طراز مركبة محدد ترغب شركات التأمين في معرفة المزيد عنه.',
        no_quotes_manual_quote_title: 'للحصول على عرض سعر يدوي اتصل بنا على 01481565',
        no_quotes_another_car: 'عُدْ واطلب تأمين سيارة أخرى',
        no_plans_available: 'لا توجد خطط متوفرة',
        we_cannot_process: 'لا يمكننا معالجة طلبك أون لاين، سيتصل بك فريقنا قريبًا. قد يكون هذا بسبب:',
        searching_for_car_insurance: 'البحث عن خطة تأمين السيارات',
        contact_for_pricing: 'اتصل بالرقم <a href="tel:01481565">01 481 565</a> للحصول على الأسعار',
        bike_details: 'تفاصيل الدراجة النارية',
        bike: 'الدراجة النارية',
        model_year:'سنة طراز',
        bike_uae_driving_license:'UAE Driving Experience',
        bike_emirates:'في أي إمارة ستقوم بتسجيل دراجتك النارية؟',
        searching_for_bike_insurance: 'Searching for bike insurance plan',
        bike_insurance_plan: 'خطط تأمين الدراجات النارية',
        third_party_damage_limit: 'حد أضرار الطرف الثالث',
        third_party_liability: 'مسؤولية الطرف الثالث',
        damage_to_your_vehicle: 'الأضرار التي تلحق بمركبتك',
        bike_year:'سنة صنع الدراجة النارية',
        engine_capacity:'سعة المحرك',
        date_of_birth: "تاريخ الولادة",
        date_of_birth_tooltip: "أدخل تاريخ ميلادك بالتنسيق الآتي DD/MM/YYYY. يساعد هذا في تقييم أهليتك وفي حساب أقساط التأمين.",
        bodily_injury_limit: "حد الإصابة الجسمانية",
        material_damage_limit: "حد الضرر المادي",
        result_allrisk: 'جميع الأخطار',
        result_allrisk_fiftyfifty: '(50/50) جميع الأخطار',
        result_totalloss: 'الخسارة الكلية',
        personal_accident_driver_passenger: "الحوادث الشخصية (السائق والركاب)",
        medical_expenses_driver_passenger: "النفقات الطبية (السائق والركاب)",
        additional_deductible:"مبلغ الخصم الإضافي",
        car_coverage:"تغطية السيارة",
        extra_coverage:"التغطية الإضافية",
        admin_fee: 'الرسوم الإدارية',
        essentials: 'الأساسيات',
        google_map: 'خرائط جوجل',
        copyrights_footer: 'التأمين موضوع طلب. © حقوق الطبع والنشر - DATE_FOOTER BuyAnyInsurance.com.',
        one_year_cover:'تغطية لسنة واحدة',
        looking_for_allrisk_plan:'البحث عن خطط التأمين ضد جميع الأخطار. سيتصل بك فريقنا قريبًا.',
        click_here_to_whatsapp_us:'انقر هنا للتواصل معنا عبر الواتساب.',
        payable_total: 'المبلغ المستحق الدفع',
        faqs: "الأسئلة المتداولة",
    }
}

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'ar', 
    globalInjection: true,
    messages,
  })

  export default i18n
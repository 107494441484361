import { Vehicle, Bike } from '@/core/types/Vehicles';
import moment from "moment"


export type State = {
  vehicle: Vehicle;
  bike: Bike;
  loading: Boolean;
  counter: number
}

export const state: State = {
  vehicle: {
    car_year: new Date().getFullYear(),
    is_new: 2,
    manufacturer_id: -1,
    model_id: -1,
    trim_level_id: -1,
    registration_emirate: 7,
    phone_number: null,
    email: null,
    name: null,
    phone_country_code: '+961',
    car_values: 0,
    is_car: 1,
    lead_source: 'website',
    btm_source: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    sid: '',
    customer_source_url: '',
    is_wizard: true,
    current_policy_active: 1,
    existing_policy_expired: 2,
    dob: '',
    // dob_month: null,
    // dob_day: null,
    // dob_year: null,
    first_registration_date: null,
    nationality: null,
    first_driving_license_country: null,
    driving_experience: 1,
    uae_driving_experience: 1,
    policy_start_date: moment().add(1, 'day').format('YYYY-MM-DD').toString(),
    claims: 0,
    claimed_insurance: 2,
    no_claim_certificate: 1,
    gcc_specification: 1,
    personal_use: 1,
    fully_comprehensive: 1,
    third_party_liability: 2,
    gender: 1,
    vehicle_type: 1,
    utmnew:''
  } as Vehicle,
  bike: {
    bike_year: 0,
    engine_capacity: -1,
    phone_number: null,
    email: null,
    name: null,
    phone_country_code: '+961',
    registration_emirate:7,
    nationality:null,
    dob: null,
    // dob_month: null,
    // dob_day: null,
    // dob_year: null,
    lead_source: 'website',
    btm_source: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    sid: '',
    customer_source_url: '',
    uae_driving_experience: 4,
    policy_start_date: moment().add(1, 'day').format('YYYY-MM-DD').toString(),
    // policy_start_date_month: new Date().getMonth() + 1,
    // policy_start_date_day: new Date().getDate(),
    // policy_start_date_year: new Date().getFullYear(),
    is_wizard: true,
    vehicle_type: 2,
    utmnew:''
  } as Bike,

  loading: false,
  counter: 0
    
};
